import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { Logo } from "../Header/Logo";
import { NavFooter } from "./NavFooter";

export function Footer() {
  const bg = useColorModeValue('gray.100', 'gray.800')
  const bgFooterBottom = useColorModeValue('gray.200', 'gray.700')
  const borderColor = useColorModeValue('gray.300', 'gray.600')
  
  return (
    <>
      <Box bg={bg} p="8" mt="10" borderTop="1px solid" borderTopColor={borderColor} >
        <Flex
          w="100%"
          maxWidth={1200}
          mx="auto"
          justify="space-around"
          align={"center"}
          direction={["column", "row"]}
          gap={["6", 0]}
        >
          <Logo />

          <Flex>
            <NavFooter />
          </Flex>
        </Flex>
      </Box>
      <Flex justify="center" py="4" bg={bgFooterBottom}>
        <Text fontSize="sm">Emidio Web Company © Todos os direitos reservados.</Text>
      </Flex>
    </>
  )
}