import { Text } from "@chakra-ui/react";

interface TextWithBackgroundProps {
  text: string;
  background: 'blue' | 'pink' | 'green';
}

export function TextWithBackground({text, background}: TextWithBackgroundProps) {
  const bg = {
    blue: 'blue.400',
    pink: 'pink.400',
    green: 'green.400'
  }
  
  return (
    <Text
      as="span"
      position="relative"
      _before={{
        content: `''`,
        height: '30%',
        width: 'full',
        bg: bg[background],
        opacity: .8,
        position: 'absolute',
        bottom: 0,
        left: 0,
        zIndex: -1,
      }}
    >
      {text} <br />
    </Text>
  )
}