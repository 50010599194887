import { mode, Styles } from '@chakra-ui/theme-tools'
import { extendTheme } from '@chakra-ui/react'

const styles: Styles = {
  global: props => ({
    body: {
      bg: mode('gray.50', 'gray.900')(props),
      color: mode('black', 'gray.50')(props)
    }
  })
}

export const theme = extendTheme({
  colors: {
    gray: {
      "900": "#0F1013"
    }
  },
  initialColorMode: 'light',
  useSystemColorMode: true,
  fonts: {
    heading: 'Inter',
    body: 'Roboto'
  },
  styles
})