import { Flex } from "@chakra-ui/react";
import { NavItem } from "../NavItem";

export function Nav() {
  return (
    <Flex gap={8} fontSize="18">
      <NavItem ariaLabel="Início" url="/">Início</NavItem>
      <NavItem ariaLabel="Contato" isExternal url="https://wa.me/message/VFS7ZBVBDVVFK1">Contato</NavItem>
    </Flex>
  )
}