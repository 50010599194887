import { Flex, LinkBox, useColorModeValue } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { Logo } from "./Logo";
import { Nav } from "./Nav";
import { ToggleThemeIcon } from "./ToggleThemeIcon";

export function Header() {
  const bg = useColorModeValue('gray.50', 'gray.900')
  const borderColor = useColorModeValue('#ddd', 'gray.800')

  return (
    <Flex
      w="100%"
      h="20"
      bg={bg}
      position="fixed"
      zIndex={1}
      borderBottom="1px solid"
      borderBottomColor={borderColor}
    >
      <Flex
        w="100%"
        maxWidth={1240}
        mx="auto"
        align="center"
        justify="space-between"
        px="4"
      >
        <LinkBox>
          <Link to="/">
              <Logo />
          </Link>
        </LinkBox>

        <Nav />

        <ToggleThemeIcon />
      </Flex>
    </Flex>
  )
}