import { NavItem } from "../NavItem";
import { NavSection } from "./NavSection";

export function NavFooter() {
  return (
    <NavSection title="Navegação">
      <NavItem ariaLabel="Início" url="/">Início</NavItem>
      <NavItem ariaLabel="Contato" isExternal url="https://wa.me/message/VFS7ZBVBDVVFK1">Contato</NavItem>
    </NavSection>
  )
}