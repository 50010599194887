import { Box, BoxProps, Stack, Text } from "@chakra-ui/react";
import { ReactNode } from "react";

interface NavSectionProps extends BoxProps {
  title: string;
  children: ReactNode;
}

export function NavSection({title, children}: NavSectionProps) {
  return (
    <Box textAlign="center">
      <Text as="span" display="block" fontWeight="bold" color="gray.400" textTransform="uppercase" mb="2" >
        {title}
      </Text>
      <Stack spacing="1">
        {children}
      </Stack>
    </Box>
  )
}