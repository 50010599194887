import { Box, BoxProps } from "@chakra-ui/react";
import { ReactNode } from "react";

interface ContainerProps extends BoxProps {
  children: ReactNode;
}

export function Container({children}: ContainerProps) {
  return (
    <Box maxWidth={1200} mx="auto">
      {children}
    </Box>
  )
}