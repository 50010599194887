import { IconButton, Link } from "@chakra-ui/react";
import { FaWhatsapp } from "react-icons/fa";

export function WhatsappButton() {
  return (
    <Link
      href="https://wa.me/message/VFS7ZBVBDVVFK1"
      isExternal
      pos="fixed"
      bottom="6"
      right="6"
    >
      <IconButton
        aria-label="Enviar mensagem no whatsapp"
        colorScheme="whatsapp"
        bg="whatsapp.400"
        color="white"
        icon={<FaWhatsapp />}
        borderRadius="full"
        fontSize="36"
        w="16"
        h="16"
      />
    </Link>
  )
}