import { Image, useColorMode } from "@chakra-ui/react";

export function Logo() {
  const { colorMode } = useColorMode()
  
  return (
    <>
      { colorMode === 'dark' ? (
        <Image src="images/logo-light.svg" alt="Emidio Web Company" w="16" maxWidth="200px" />
      ) : <Image src="images/logo.svg" alt="Emidio Web Company" w="16" maxWidth="200px" /> }
    </>
  )
}