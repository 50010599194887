import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { WhatsappButton } from './components/WhatsappButton';
import { Home } from './pages/home';
import { theme } from './styles/theme';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
      <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="*" element={<Home />} />
        </Routes>
        <WhatsappButton />
      <Footer />
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
