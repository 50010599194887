import { Image, Stack, StackProps, Text, useColorModeValue } from "@chakra-ui/react";
import { motion } from "framer-motion";

interface FeatureProps {
  title: string;
  text: string;
  image: string;
}

const MotionStack = motion<StackProps>(Stack)

export function Feature({ title, text, image }: FeatureProps) {
  const bg = useColorModeValue('gray.100', 'gray.800')
  const color = useColorModeValue('gray.600', 'gray.300')
  
  return (
    <MotionStack
      bg={bg}
      rounded="lg"
      initial={{ x: -100 }}
      whileInView={{ x: 0 }}
      viewport={{ once: true }}
    >
      <Image
        src={image}
        alt={title}
        borderTopRadius="lg"
        maxHeight={200}
        objectFit="cover"
      />
      <Stack p="6" pb="8">
        <Text fontWeight={600} fontSize="lg" fontFamily="Inter">{title}</Text>
        <Text color={color}>{text}</Text>
      </Stack>
    </MotionStack>
  )
}