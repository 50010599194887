import { Box, Heading, SimpleGrid } from '@chakra-ui/react'
import Helmet from 'react-helmet'
import { Container } from '../components/Container'

import { Feature } from '../components/Feature'
import { Hero } from '../components/Home/Hero'

export function Home() {
  return (
    <>
      <Helmet>
        <title>Página Inicial | Emidio Web Company</title>
      </Helmet>
      <Box as="main" pt="20">
        <Hero />

        <Container>
          <Heading mb="8" textAlign="center">O que fazemos?</Heading>
          <SimpleGrid
            columns={[1, 2]}
            spacing="6"
            maxWidth={1200}
            mx="auto"
          >
            <Feature
              title="Websites"
              text={'Você pode ter o website da sua empresa presente no Google e visível para milhares de pessoas. Utilizamos as melhores práticas e tecnologias do mercado.'}
              image="/images/website.jpg"
            />
            <Feature
              title="Landing Pages"
              text={'Tenha páginas que prendem a atenção e convertem muito! Páginas de alta performance para o melhor desempenho de vendas do seu produto e captação de leads da sua empresa.'}
              image="/images/landing-page.jpg"
            />
            <Feature
              title="Blogs"
              text={' Publique o seu conteúdo facilmente na internet e tenha milhares de leitores. Além disso, o blog pode ajudar a posicionar melhor o seu site em buscadores, como o Google.'}
              image="/images/blog.jpg"
            />
            <Feature
              title="Lojas Virtuais"
              text={'Comece já a vender os seus produtos na internet, com processos automatizados, de maneira que se torne mais fácil escalar o seu negócio online.'}
              image="/images/lojas-virtuais.jpg"
            />
          </SimpleGrid>
        </Container>
      </Box>
    </>
  )
}