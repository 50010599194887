import { IconButton, useColorMode } from "@chakra-ui/react";
import { FaSun, FaMoon } from 'react-icons/fa'

export function ToggleThemeIcon() {
  const { colorMode, toggleColorMode } = useColorMode()
  
  return (
    <>
      {colorMode === 'dark' ? (
        <IconButton
          onClick={toggleColorMode}
          aria-label="Mudar para tema claro"
          title="Mudar para tema claro"
          icon={<FaSun />}
          size="lg"
          fontSize="2xl"
          bg="transparent"
        />
      ) : 
      <IconButton
        onClick={toggleColorMode}
        title="Mudar para tema escuro"
        aria-label="Mudar para tema escuro"
        icon={<FaMoon />}
        size="lg"
        fontSize="2xl"
        bg="transparent"
      /> }
    </>
  )
}