import { Text } from "@chakra-ui/react";

interface HighlightedTextProps {
  text: string
}

export function HighlightedText({ text }: HighlightedTextProps) {
  return (
    <Text
      as="span"
      color="blue.400"
    > {text}
    </Text>
  )
}