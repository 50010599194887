import { Link as ChakraLink } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { ReactNode } from "react";

interface NavItemProps {
  url: string,
  children: ReactNode;
  isExternal?: boolean;
  ariaLabel: string;
}

export function NavItem({url, children, isExternal, ariaLabel}: NavItemProps) {
  return (
    <>
      { isExternal ?
        <ChakraLink aria-label={ariaLabel} isExternal href={url}  cursor="pointer" transition="color .2s" _hover={{color: 'blue.500'}}>
          {children}
        </ChakraLink>
        :
        <ChakraLink to={url} as={Link} cursor="pointer" transition="color .2s" _hover={{color: 'blue.500'}}>
          {children}
        </ChakraLink>
      }
    </>
  )
}