import { Box, Flex, Heading, Image, Text, useColorModeValue } from "@chakra-ui/react";
import { HighlightedText } from "../HighlightedText";
import { TextWithBackground } from "../TextWithBackground";

export function Hero() {
  const color = useColorModeValue('gray.900', 'gray.300')
  
  return (
    <Flex
      as="section"
      direction={["column", "row"]}
      ml="auto"
      mb="20"
      align="center"
      boxShadow="base"
    >
      <Box w={["100%", "55%"]} px={["4", "20"]} py={["8", "0"]} textAlign="center">
        <Heading
          as="h1"
          fontWeight={900}
          fontSize={{ base: '4xl', sm: '4xl', lg: '6xl' }}
          letterSpacing={-2}
        >
          <TextWithBackground text="Não fique de fora " background="blue" />
          da
          <HighlightedText text="revolução digital" />
        </Heading>
          <Text
            color={color}
            mt="2"
            fontSize={["md", "xl"]}
          >
            Marque sua presença online com um site criativo, bonito e de alta conversão.
          </Text>
      </Box>
      <Flex w={["100%", "55%"]} justify="right">
        <Image src="/images/home-hero.jpg" alt="Laptop illustration"/>
      </Flex>
    </Flex>
  )
}